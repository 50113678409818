import * as React from "react";
import * as style from "./styles/legal.module.scss";
import { graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import PageWrapper from "components/PageWrapper";
import HeaderSection from "components/HeaderSection";
import BoundedSection from "components/BoundedSection";

const SecurityPage = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper
      seoTitle={t("legal.seo.title")}
      seoDescription={t("legal.seo.description")}
    >
      <HeaderSection
        title={t("security.header.title")}
        description={<Trans i18nKey="security.header.description" />}
      />
      <BoundedSection className={style.LegalInfo}>
        <Trans i18nKey="security.intro" />
        <Trans
          i18nKey="security.goals"
          components={{ ul: <ul />, li: <li /> }}
        />
        <Trans
          i18nKey="security.compromises"
          components={{ ul: <ul />, li: <li /> }}
        />
        <Trans i18nKey="security.outro" />
      </BoundedSection>
    </PageWrapper>
  );
};

export default SecurityPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
